<template>
    <div class="modal-profile-item-selector edit-profile-detail items-start bg-main select-region">
        <div class="back-button-wrapper" @click="onClickClose">
            <img
                class="m-r-2 back-button"
                :src="require(`@/assets/images/icons/close.png`)"
                width="24px"
                height="24px"
            />
            <span class="f-18 m-l-12 c-black">지역 선택</span>
        </div>
        <section class="section m-t-24">
            <div class="title">
                <div class="text" v-html="'어디서 만나면 편하신가요?'" />
            </div>
            <div class="content">
                <div class="desc m-b-16" v-html="'남성분이 제안한 만남 지역 중 한 곳을 골라주세요.'" />
                <div class="items">
                    <div v-for="(val, idx) in locations" :key="idx">
                        <div class="item" :class="{ selected: selectedLocation === idx }" @click="onClickLocation(idx)">
                            <i class="material-icons-outlined">location_on</i>
                            <div class="val">{{ val }}</div>
                        </div>
                    </div>
                    <div @click="alternativeRegion" class="item">
                        <i class="material-icons-outlined">location_on</i>
                        <div class="val">다른 곳에서 만나고 싶으신가요?</div>
                    </div>
                </div>
            </div>
        </section>
        <BottomButton @click="submit" :disabled="disabled" :label="'약속 확정'" />
    </div>
</template>

<script>
export default {
    name: 'ModalSelectRegion',
    props: ['options'],
    methods: {
        onClickLocation(idx) {
            this.selectedLocation = idx
        },
        onClickClose() {
            this.$emit('close')

            this.$nativeBridge.postMessage({
                action: 'setBackgroundColor',
                value: {
                    top: '#FAF0E1',
                    bottom: '#FFF9EF',
                },
            })
        },
        async submit() {
            if (this.selectedLocation === null || this.selectedLocation === undefined) {
                this.$modal.basic({
                    title: '지역을 선택해주세요.',
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'c-primary',
                        },
                    ],
                })
                return
            }
            const idx = await this.$modal.basic({
                title: '아래 내용대로 약속이 확정됩니다.',
                body: `- <b>${this.$moment(this.options.content.date_confirmed).format(
                    'MM월 DD일 HH시 mm분'
                )} ${this.$translate(
                    this.$moment(this.options.content.date_confirmed).format('dddd').toUpperCase()
                )}요일 <br />- ${this.locations[this.selectedLocation]}에서 ${
                    this.promiseType
                } 예정</b><br>변경이 어려우니 다시 한번 확인해주세요.`,
                buttons: [
                    {
                        label: 'CANCEL',
                        class: 'btn-default',
                    },
                    {
                        label: 'CONFIRM',
                        class: 'btn-primary',
                    },
                ],
            })
            if (idx === 1) this.$emit('close', this.locations[this.selectedLocation])
        },
        alternativeRegion() {
            this.$modal.basic({
                body:
                    '남성분이 제안한 지역 중 한 곳을 선택하여 약속을 확정한 후,<br />약속 관리 페이지에서 약속 변경을 통해 상대방과 조율이 가능해요.',
                buttons: [
                    {
                        label: 'CONFIRM',
                        class: 'c-primary',
                    },
                ],
            })
        },
    },
    data: () => ({
        selectedLocation: null,
    }),
    computed: {
        date() {
            return {
                title: 'SELECT_DATE',
                desc: this.isAtype ? 'SELECT_DATE_DESC_A' : 'SELECT_DATE_DESC_B',
            }
        },
        disabled() {
            return this.selectedLocation === null
        },
        me() {
            return this.$store.getters.me
        },
        locations() {
            return this.options.places
        },
        promiseType() {
            if (this.options.content.meet_type_confirmed === 'meal') {
                return '식사'
            } else {
                return '커피'
            }
        },
    },
}
</script>
<style scoped lang="scss">
.select-region {
    overflow-y: auto;

    .section {
        margin-bottom: 48px;
        padding: 0 16px;
        color: black;

        .title {
            display: flex;
            align-items: center;
            font-size: 20px;
            margin-bottom: 8px;
            @include spoqa-f-medium;
        }
        .content {
            font-size: 16px;

            .item {
                justify-content: unset;
                font-size: 16px;
                color: $grey-06;
                padding-left: 16px;
                border: 1px solid $grey-02;

                .val {
                    color: black;
                }

                i {
                    font-size: 16px;
                    color: black;
                    margin-right: 8px;
                }

                &.selected {
                    border: 1px solid $blue-primary !important;
                    background-color: #f0f8ff;
                }
                &.selected {
                    color: $blue-primary;
                }
            }
        }
    }
    ::v-deep .bottom-button {
        border: 1px solid white;
    }
}
</style>
